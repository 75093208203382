import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from './StoreProvider';
import debounce from 'lodash.debounce';
import LoadingSpinner from './shared/components/LoadingSpinner';

const getTimestamp = () => new Date().toISOString();

const AccountTypeProtectedRoute = observer(({ children, allowedAccountTypes = [] }) => {
  const store = useStore();
  const { user, accountType } = store;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleAuthCheck = debounce(() => {
      if (user) {
        if (location.pathname.startsWith('/u/')) {
          setIsLoading(false);
          return;
        }

        if (accountType) {
          if (Array.isArray(allowedAccountTypes) && allowedAccountTypes.includes(accountType)) {
            setIsLoading(false);
          } else {
            navigate('/login');
          }
        } else {
          setIsLoading(false);
        }
      } else {
        if (location.pathname.startsWith('/u/assessment/')) {
          const linkId = location.pathname.split('/').pop();
          store.setLinkId(linkId);
        }

        const path = location.pathname.startsWith('/c') ? '/login' : `/register/user`;
        navigate(path, { state: { from: location } });
      }
    }, 300);

    handleAuthCheck();
    return () => {
      handleAuthCheck.cancel();
    };
  }, [user, accountType, navigate, location, store, allowedAccountTypes]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return null;
  }

  return children;
});

export default AccountTypeProtectedRoute;
