// src/components/LoadingSpinner.js
import React from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoadingSpinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <ClipLoader color={"#123abc"} loading={true} css={override} size={150} />
    </div>
  );
};

export default LoadingSpinner;
