import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";

// colors
const primary = "#423FD3";
const secondary = "#6E6BF2";
const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const background = "#f5f5f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";
const white = "#FFFFFF";
const lightGray = "#D3D3D3";
const borderColor = "rgba(0, 0, 0, 0.13)";

// border
const borderWidth = 2;

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    common: {
      black,
      darkBlack
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark
    },
    tonalOffset: 0.2,
    background: {
      default: background,
      paper: white
    },
    divider: lightGray,
    spacing
  },
  breakpoints: {
    values: {
      xl,
      lg,
      md,
      sm,
      xs
    }
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static"
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: lightGray,
        height: borderWidth
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      color: black,
    },
    h2: {
      color: black,
    },
    h3: {
      color: black,
    },
    h4: {
      color: black,
    },
    h5: {
      color: black,
    },
    h6: {
      color: black,
    },
    subtitle1: {
      color: black,
    },
    subtitle2: {
      color: black,
    },
    body1: {
      color: black,
    },
    body2: {
      color: black,
    },
    button: {
      color: white,
    },
    caption: {
      color: black,
    },
    overline: {
      color: black,
    }
  }
}));

export default responsiveFontSizes(theme);
