import { makeAutoObservable, runInAction } from "mobx";
import { getFirestore, doc, getDoc } from 'firebase/firestore';

class UserStore {
  user = null;
  accountType = null;
  companyName = '';
  companyLogo = '';
  linkId = null;
  InterviewLimitReached = false;
  onboardingPassed = 0;

  constructor() {
    makeAutoObservable(this);
    this.loadUserFromLocalStorage();
    console.log("UserStore initialized with:", this);
  }

  async fetchUserDetails(userId) {
    const db = getFirestore();
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const data = userDocSnap.data();
      runInAction(() => {
        this.accountType = data?.accountType || null;
        this.companyName = data?.companyName || '';
        this.companyLogo = data?.companyLogo || '';
        this.onboardingPassed = data?.onboardingPassed || 0;
        this.saveUserToLocalStorage();
      });
    } else {
      console.error('User document does not exist');
      runInAction(() => {
        this.resetUserState();
      });
    }
  }

  async fetchInterviewLimitStatus(userId) {
    const db = getFirestore();
    const subscriptionDocRef = doc(db, "subscription_status", userId);
    const subscriptionDocSnap = await getDoc(subscriptionDocRef);

    if (subscriptionDocSnap.exists()) {
      runInAction(() => {
        this.InterviewLimitReached = subscriptionDocSnap.data().InterviewLimitReached || false;
        this.saveUserToLocalStorage();
      });
    } else {
      console.error('Subscription status document does not exist');
      runInAction(() => {
        this.InterviewLimitReached = false;
        this.saveUserToLocalStorage();
      });
    }
  }

  async initializeUser(authUser) {
    if (authUser && authUser.emailVerified) {
      const userId = authUser.uid;
      await this.fetchUserDetails(userId);
      await this.fetchInterviewLimitStatus(userId);
      runInAction(() => {
        this.user = authUser;
        this.saveUserToLocalStorage();
        console.log("User initialized:", this);
      });
    } else {
      this.logout();
    }
  }

  logout() {
    runInAction(() => {
      this.resetUserState();
    });
    this.clearLocalStorage();
  }

  resetUserState() {
    this.user = null;
    this.accountType = null;
    this.companyName = '';
    this.companyLogo = '';
    this.InterviewLimitReached = false;
    this.onboardingPassed = 0;
  }

  setLinkId(linkId) {
    runInAction(() => {
      this.linkId = linkId;
    });
    this.saveUserToLocalStorage();
  }

  saveUserToLocalStorage() {
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('accountType', this.accountType);
    localStorage.setItem('companyName', this.companyName);
    localStorage.setItem('companyLogo', this.companyLogo);
    localStorage.setItem('InterviewLimitReached', this.InterviewLimitReached);
    localStorage.setItem('onboardingPassed', this.onboardingPassed);
    localStorage.setItem('linkId', this.linkId);
  }

  loadUserFromLocalStorage() {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      this.user = storedUser;
      this.accountType = localStorage.getItem('accountType');
      this.companyName = localStorage.getItem('companyName');
      this.companyLogo = localStorage.getItem('companyLogo');
      this.InterviewLimitReached = JSON.parse(localStorage.getItem('InterviewLimitReached'));
      this.onboardingPassed = JSON.parse(localStorage.getItem('onboardingPassed'));
      this.linkId = localStorage.getItem('linkId');
    }
  }

  clearLocalStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('accountType');
    localStorage.removeItem('companyName');
    localStorage.removeItem('companyLogo');
    localStorage.removeItem('InterviewLimitReached');
    localStorage.removeItem('onboardingPassed');
    localStorage.removeItem('linkId');
  }

  updateCompanyDetails(companyName, companyLogo) {
    try {
      console.log("Attempting to update company details with:", { companyName, companyLogo });
      console.log("Current UserStore instance:", this);
      runInAction(() => {
        if (!this) {
          throw new Error("UserStore instance is undefined.");
        }
        console.log("Setting companyName:", companyName);
        this.companyName = companyName;
        console.log("Setting companyLogo:", companyLogo);
        this.companyLogo = companyLogo;
        console.log("Saving to local storage with new values.");
        this.saveUserToLocalStorage();
        console.log("Company details updated successfully.");
      });
    } catch (error) {
      console.error("Error in updateCompanyDetails:", error);
    }
  }
}

const userStore = new UserStore();
export default userStore;
