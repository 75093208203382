import React from 'react';
import { createContext, useContext } from 'react';
import userStore from './store';

const StoreContext = createContext(userStore);

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={userStore}>
    {children}
  </StoreContext.Provider>
);

export const useStore = () => useContext(StoreContext);
